import React from 'react';
import { CircularProgress } from '@mui/material';

const Fallback = () => {
  return (
    <div className="fallback">
      <figure>
        <img src="/images/get_prop2.png" alt="" />
      </figure>
      <CircularProgress
        sx={{
          color: '#F5F7F8',
        }}
      />
    </div>
  );
};

export default Fallback;
