import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../app/store';

export interface ModalType {
  emailModal: boolean;
  feedBackModal: boolean;
  agentEmail: string;
  agentId: string;
  propertyId: string;
  MetricName: string;
}

const initialState: ModalType = {
  emailModal: false,
  feedBackModal: false,
  agentEmail: '',
  agentId: '',
  propertyId: '',
  MetricName: 'feet',
};

const modalSlice = createSlice({
  name: 'modalType',
  initialState,
  reducers: {
    openEmailModal(
      state,
      action: PayloadAction<{ agentId: string; propertyId: string }>,
    ) {
      state.emailModal = true;

      state.agentId = action.payload.agentId;
      state.propertyId = action.payload.propertyId;
    },
    // Action to close the modal
    closeEmailModal(state) {
      state.emailModal = false;
      state.agentId = '';
    },
    openFeedBackModal(state) {
      state.feedBackModal = true;
    },
    closeFeedBackModal(state) {
      state.feedBackModal = false;
    },

    changeAreaMetric(state, action: PayloadAction<string>) {
      state.MetricName = action.payload;
    },
  },
});

export const {
  openEmailModal,
  closeEmailModal,
  openFeedBackModal,
  closeFeedBackModal,
  changeAreaMetric,
} = modalSlice.actions;
export const openModalState = (state: RootState) => state.modalSlice.emailModal;
export const isOpenFeedBackModal = (state: RootState) =>
  state.modalSlice.feedBackModal;
export const agentEmail = (state: RootState) => state.modalSlice.agentEmail;
export const agentId = (state: RootState) => state.modalSlice.agentId;
export const propertyId = (state: RootState) => state.modalSlice.propertyId;
export const MetricName = (state: RootState) => state.modalSlice.MetricName;

export default modalSlice.reducer;
