import { getFromStorage, setToStorage } from '../constants/storage';
import { STORAGE_KEYS } from '../constants/storageKeys';
import { getMessaging, getToken } from 'firebase/messaging';
import app from './firebasekeys';

const firebaseCloudMessaging = {
  // eslint-disable-next-line consistent-return
  init: async () => {
    try {
      const messaging = getMessaging(app);
      const tokenInLocalForage = await getFromStorage(STORAGE_KEYS.fcmToken);

      if (tokenInLocalForage) {
        return tokenInLocalForage;
      }

      const status = await Notification.requestPermission();
      if (status && status === 'granted') {
        console.log('firebaseKeys');
        const fcm_token = await getToken(messaging, {
          vapidKey:
            'BC0M1boQbMKd-WnMKuC1Y9n5kfL_asvc0wqQl0zis7mYX8j-2xm0y8qWXaBicF2PPgmoGXmYZP4Orp0vnrJ41VI',
        });

        if (fcm_token) {
          console.log({ fcm_token });
          await setToStorage(STORAGE_KEYS.fcmToken, fcm_token);
          return fcm_token;
        }
      }
    } catch (error) {
      console.error(error);
      return null;
    }
    // }
  },
};

export { firebaseCloudMessaging };
