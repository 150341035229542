export const API_URL =
  'https://getpropmicroapi.appgrowthcompany.com/operational/api/v1/User/';

export const BLOG_URL = 'https://webgetprop.appgrowthcompany.com/';

export const WEBSITE_URL = 'https://getpropweb.appgrowthcompany.com/';

export const END_POINTS = {
  //Authentication
  signup: 'signup',
  verifyOtp: 'verifyOtp',
  sendOtp: 'sendOtp',
  updateProfile: 'updateProfile',
  upload: 'Upload/uploadFiles',
  logout: 'logout',
  socialLogin: 'socialLogin',
  login: 'login',
  deleteProfile: 'deleteProfile',
  resetPassword: 'resetPassword',
  getProfile: 'getProfile',
  forgotPassword: 'forgotPassword',
  changePassword: 'changePassword',

  validateAccount: 'validateAccount', //for otp send
  verifyAccount: 'verifyAccount',

  propertyListing: 'propertyListing',
  propertyFilter: 'propertyFilter',

  //Token
  tokens: 'tokens',
  topupByCompany: 'topupByCompany',
  settings: 'settings',
  category: 'category',
  property: 'property',
  agentRequest: 'agentRequest',

  recommendedProperties: 'recommendedProperties',
  dashboard: 'dashboard',
  premiumListing: 'premiumListing',

  wishlist: 'wishlist',
  propertyDetailToken: 'propertyDetailToken',
  getPropertyTransactionDetails: 'getPropertyTransactionDetails',

  //ADS
  ads: 'agency/ads',

  //agents
  agents: 'agents',
  titanInsights: 'titanInsights',
  agencyDashboard: 'agencyDashboard',
  agent:"agent",

  cms: 'cms',
  contactUs: 'contactUs',
  boostPlans: 'boostPlans',
  boostProfile: 'boostProfile',
  subscriptionPlans: 'subscriptionPlans',
  subscription: 'subscription',
  amenities: 'amenities',
  propertyType: 'propertyType',
  propertyCondition: 'propertyCondition ',
  //chat
  chatRequest: 'chatRequest',
  updateUserRequest: 'updateUserRequest',
  chats: 'chats',
  notifications: 'notifications',
  recentNotifications: 'recentNotifications',
  propertiesLocationWise: 'propertiesLocationWise',
  adsBanner: 'ads',

  //reels
  reels: 'reels',

  // listing calculator
  propertyCalculator: 'propertyCalculator',
  listing: 'listing',
  chooseListingPlans: 'chooseListingPlans',

  // insights
  leadsInsights: 'leadsInsights',
  // titanInsights: 'titanInsights',
  listingPerformanceDashboard: 'listingPerformanceDashboard',
  leads: 'leads',
  companyPerformance: 'companyPerformance',
  agentInsights: 'agentInsights',
  connectWithEmail: 'connectWithEmail ',
  feedbackForm: 'feedbackForm',
  findAgent: 'findAgent',
  alert: 'alert',
  searchHistory: 'searchHistory',
  agentDetails: 'agentDetails',
  leadsInsightDetails: 'leadsInsightDetails',
  drafts: 'drafts',
  requestedPropertiesByAgent: 'requestedPropertiesByAgent',
  updatePropertyRequest: 'updatePropertyRequest',
  getintouch: 'getintouch',
  underOfferStatus: 'underOfferStatus',
  trackRecord: 'trackRecord',
  leadsWhatsApp: 'leads',
  reportProperty: 'reportProperty',
  subscribingNewsletter: 'subscribingNewsletter',
  deleteAgentAccount: 'deleteAgentAccount',
  rating: 'rating',
};
